<template>
  <!--维护通知-->
  <van-dialog   show-cancel-button>
    <div class="p-3 pre-wrap fs-14">
      <div class="flex h-center">
        朋友圈增加了大量优质视频啦‼<br/>

        🉑一键开启，自动分发<br/>

        让你的朋友圈更加「生动灵活」
      </div>
    </div>
  </van-dialog>
</template>

<script>
export default {
  name: "TheNoticeDialog"
}
</script>
<script setup>

// const props = defineProps({
//   modelValue: {
//     type: Boolean,
//     default: false
//   },
// })
// const emits = defineEmits('update:modelValue')
//
// const myVisible = computed({
//   set(val) {
//     emits('update:modelValue', val)
//   },
//   get() {
//     return props.modelValue
//   }
// })
</script>

<style scoped>

</style>
